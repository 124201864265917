import React from 'react';
import {useState, useEffect, Suspense} from 'react';
import fingerprint from './fingerprint';
import config from './config.json';
const AllDomains = React.lazy(() => import('./manage-domains'));

/*
const RestAPI = React.lazy(() => import('./rest-api'));
const ResizeGrid = React.lazy(() => import('./resize-grid'));
const AIBuilder = React.lazy(() => import('./ai-builder'));
const VectorizeDB = React.lazy(() => import('./vectorize-db'));
*/

const isAllow = function() {
    const allowDevices =  [
        "4fa2dbf48b8384056a098ce90cc9d6d2"
    ];
    const visitorId = fingerprint.getVisitorId();
    if(allowDevices.indexOf(visitorId) === -1) {
        return false;
    }
    return true;
}




const loadScript = function(jqueryFile) {
    return new Promise(function(resolve, reject){
        var jqueryScript = document.createElement('script');
        jqueryScript.src = jqueryFile;
        jqueryScript.async = true;
        jqueryScript.onerror = function(){
            reject();
        }
        jqueryScript.onload = function(){
            resolve();
        }
        document.head.appendChild(jqueryScript)
    })
}


const loadCSS = function(file) {
    var link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href =  file;
    document.head.appendChild(link)
}

const loading = false;
export default function Projects() {
    const allowedUser = isAllow();
    useEffect(()=>{
        if(!loading) {
            config.projects.forEach(function(v){
                if(v.status) {
                    v.css.forEach(function(v){
                        loadCSS(v);
                    })
                    v.scripts.forEach(function(){
                        loadScript(v);
                    })
                }
            })
        }
    },[]);
    if(!allowedUser) {
        return <div className="container vw-100 vh-100 d-flex align-items-center">
            <div className="m-auto p-3 bg-light border border-2 rounded border-dark text-center" style={{width:"320px"}}>
                <h2>User not allowed</h2>
            </div>
        </div>
    }
    return <>
    <Suspense fallback={<div>Loading...</div>}>
    {
         config.projects.map(function(v){
            if(v.status) {
                if(v.name === "all-domains") {
                    return <AllDomains />
                }
                // if(v.name === "rest-api") {
                //     return <RestAPI />
                // }
                // if(v.name === "resize-grid") {
                //     return <ResizeGrid />
                // }
                // if(v.name === "ai-builder") {
                //     return <AIBuilder />
                // }
                // if(v.name === "vectorize-db") {
                //     return <VectorizeDB />
                // }
            }
            return null;
        })
    }
    </Suspense>
    
    </>
}
