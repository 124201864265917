import CryptoJS from 'crypto-js';

const app = {
    getOS:function() {
        var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
        if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
        } else if (/Android/.test(userAgent)) {
        os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
        }
        return os;
    },
    getBrowserType:function() {
        const test = regexp => {
            return regexp.test(navigator.userAgent);
          };
        
        
          if (test(/opr\//i) || !!window.opr) {
            return 'Opera';
          } else if (test(/edg/i)) {
            return 'Microsoft Edge';
          } else if (test(/chrome|chromium|crios/i)) {
            return 'Google Chrome';
          } else if (test(/firefox|fxios/i)) {
            return 'Mozilla Firefox';
          } else if (test(/safari/i)) {
            return 'Apple Safari';
          } else if (test(/trident/i)) {
            return 'Microsoft Internet Explorer';
          } else if (test(/ucbrowser/i)) {
            return 'UC Browser';
          } else if (test(/samsungbrowser/i)) {
            return 'Samsung Browser';
          } else {
            return 'Unknown browser';
          }
    },
    getScreenSize:function() {
        return `${window.screen.width}x${window.screen.height}`
    },
    getVisitorId:function() {
        const userAgent = window.navigator.userAgent;
        const appVersion = window.navigator.appVersion;
        const extraString = `${window.screen.colorDepth}${window.screen.pixelDepth}`
        const visitorId = CryptoJS.MD5(userAgent + appVersion + app.getBrowserType() + app.getOS() + app.getScreenSize() + extraString).toString();
        console.log(visitorId);
        return visitorId;
    }
}

export default app;